import * as React from 'react';
import ReactSVG from 'react-svg'
import InstaIconCashew from '../../../../../assets/images/applicants/landing_page/instacart-logo-cashew.svg'
import Facebook from '../../../../../assets/images/applicants/landing_page/facebook-cashew.svg'
import Instagram from '../../../../../assets/images/applicants/landing_page/instagram-cashew.svg'
import Twitter from '../../../../../assets/images/applicants/landing_page/twitter-cashew.svg'
import LinkedIn from '../../../../../assets/images/applicants/landing_page/linkedin-cashew.svg'
import DownArrow from '../../../../../assets/images/applicants/landing_page/down-arrow-cashew.svg'
import PrivacyIcon from '../../../../../assets/images/applicants/your-privacy-choices-icon.svg'

const { useState, useRef } = React;

type FooterLink = {
  key: string;
  header: string;
  url?: string;
  path?: string;
}

type FooterGroup = {
  header: string;
  key: string;
  links: FooterLink[];
}

function createFooterContent({ currentCountry, locale }): FooterGroup[] {
  const formattedLocale = locale.replace('_', '-')

  return [
    {
      header: 'Instacart',
      key: 'instacart',
      links: [
        {
          key: 'careers',
          header: 'Careers',
          url: 'https://instacart.careers/current-openings/',
        },
        {
          key: 'latestNews',
          header: 'Latest News',
          url: 'https://news.instacart.com/',
        },
        {
          key: 'contactUs',
          header: 'Contact Us',
          url: 'https://aboutinstacart.com/contact/',
        },
        {
          key: 'helpCenter',
          header: 'Help Center',
          url: 'https://www.instacart.com/help'
        }
      ],
    },
    {
      header: 'Shopper',
      key: 'shopper',
      links: [
        {
          key: 'fss',
          header: 'Full-service Shopper',
          path: '/role/full-service'
        },
        {
          key: 'earnings',
          header: 'Earnings',
          url: 'https://www.instacart.com/company/shoppers/shopper-earnings'
        },
        {
          key: 'jobs',
          header: 'Jobs',
          path: '/instacart-jobs'
        },
      ],
    },
    {
      header: 'Top Cities',
      key: 'cities',
      links: [{
        key: 'viewAll',
        header: 'View All Cities',
        path: '/instacart-jobs'
      }],
    },
    {
      header: 'Get the App',
      key: 'app',
      links: [
        {
          key: 'iosShopper',
          header: 'iOS Shopper App',
          url: `https://apps.apple.com/${currentCountry}/app/instacart-shopper/id1454056744?l=${formattedLocale}`,
        },
        {
          key: 'androidShopper',
          header: 'Android Shopper App',
          url: `https://play.google.com/store/apps/details?id=com.instacart.shopper&hl=${formattedLocale}`,
        },
      ],
    },
  ]
}

const SocialMedia = () => (
  <div className="flex">
    <a href="https://www.linkedin.com/company/instacart/" title="Instacart on LinkedIn" className="mr-2.5"><ReactSVG path={LinkedIn} /></a>
    <a href="https://www.facebook.com/Instacart/" title="Instacart on Facebook" className="mx-2.5"><ReactSVG path={Facebook} /></a>
    <a href="https://twitter.com/Instacart" title="Instacart on Twitter" className="mx-2.5"><ReactSVG path={Twitter} /></a>
    <a href="https://www.instagram.com/instacart/" title="Instacart on Instagram" className="ml-2.5"><ReactSVG path={Instagram} /></a>
  </div>
)

const Copyright = ({ text }: { text: string }) => (
  <p className="md:w-48 my-2 text-xs order-1">{text}</p>
)

const PrivacyPolicy = ({ text, currentHost, privacyChoicesEnabled }: { text: string; currentHost: string; privacyChoicesEnabled: 'true' | 'false' }) => (
  <div className="flex md:flex-col">
    <p className="text-xs order-1 mr-4 md:mt-2">
      <a href={`${currentHost}/privacy-policy`}>{text}</a>
    </p>
    {privacyChoicesEnabled === 'true' &&
      <a href={`${currentHost}/privacy_choices`} className="text-xs order-1 md:mt-0.5 md:mb-2">
        <div className="flex items-center">
          Your Privacy Choices
          <ReactSVG path={PrivacyIcon} svgStyle={{ width: 34, height: 14 }} />
        </div>
      </a>
    }
  </div>
)

const FooterLinks = ({ formattedContent, currentHost }) => {
  // TODO: fix to be number, not boolean
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef([])

  const openMenu = (i) => {
    const item = menuRef.current[i]
    const h = item.scrollHeight === item.clientHeight ? '0' : item.scrollHeight
    setIsOpen(prevState => (prevState !== i ? i : false))
    menuRef.current[i].style.maxHeight = `${h}px`
  }

  return (
    <div className="footer-links md:ml-12 md:w-4/5">
      <div className="flex flex-col md:flex-row justify-between">
        {formattedContent.map((col, i) => {
          return (
            <div className="flex flex-col border-b border-kale-light md:border-none" key={i}>
              <div
                className="flex justify-between items-center mt-6 md:my-0 "
                role="button"
                onClick={() => openMenu(i)}
              >
                <p className="font-body font-medium mb-6">{col.header}</p>
                <ReactSVG path={DownArrow} className={`md:hidden mb-6 transition-all transform ${isOpen === i ? 'rotate-180' : 'rotate-0'}`} />
              </div>
              <span
                className="overflow-hidden flex flex-col max-h-0 md:max-h-full"
                ref={el => { menuRef.current[i] = el }}
              >
                {col.links.map(link =>
                  <a href={link.url || `${currentHost}${link.path}`} key={link.header} className="text-xs">{link.header}</a>
                )}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const Footer = ({
  content: {
    copyright,
    privacyPolicy,
  },
  content,
  currentHost,
  currentCountry,
  locale,
  topCities = {},
  privacyChoicesEnabled,
}) => {
  const formattedTopCities = [];
  const footerContent = createFooterContent({ currentCountry, locale })

  Object.keys(topCities).forEach(key => {
    formattedTopCities.push({ header: topCities[key], path: `/${key}` })
  })

  footerContent.forEach((col) => {
    col.header = content[col.key].header || col.header
    if (col.key === 'cities') {
      col.links = [...formattedTopCities, ...col.links]
    }
    col.links.forEach((link, index) => {
      if (!window.gon.isUsHost && link.key === 'iss') {
        col.links.splice(index, 1);
      }
      link.header = content[col.key][link.key] || link.header
    })
  })

  return (
    <footer className="bg-kale text-cashew font-body font-light">
      <div className="w-full lg:max-w-7xl m-auto px-6 py-12 xl:px-0 lg:pt-32 lg:pb-24 content has-text-centered">
        <div className="flex flex-col md:flex-row">
          <div className="order-1 md:order-none md:w-1/5">
            <div className="flex flex-col my-6 md:my-0">
              <div className="mb-5 hidden md:block">
                <ReactSVG path={InstaIconCashew} />
              </div>
              <PrivacyPolicy text={privacyPolicy} currentHost={currentHost} privacyChoicesEnabled={privacyChoicesEnabled} />
              <Copyright text={copyright} />
              <div className="my-10 md:mt-14 order-first md:order-1">
                <SocialMedia />
              </div>
            </div>
          </div>
          <FooterLinks formattedContent={footerContent} currentHost={currentHost} />
        </div>
      </div>
    </footer>
  )
}
